import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2c3142a1 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _2990a780 = () => interopDefault(import('../pages/academy.vue' /* webpackChunkName: "pages/academy" */))
const _75218e03 = () => interopDefault(import('../pages/academy/index.vue' /* webpackChunkName: "pages/academy/index" */))
const _c421b8ae = () => interopDefault(import('../pages/academy/courses.vue' /* webpackChunkName: "pages/academy/courses" */))
const _57cf2c1f = () => interopDefault(import('../pages/academy/course/detail/_id/_slug/index.vue' /* webpackChunkName: "pages/academy/course/detail/_id/_slug/index" */))
const _0bc9c1e3 = () => interopDefault(import('../pages/academy/course/watch/_id/_slug/index.vue' /* webpackChunkName: "pages/academy/course/watch/_id/_slug/index" */))
const _09fde1b6 = () => interopDefault(import('../pages/buyerprograms.vue' /* webpackChunkName: "pages/buyerprograms" */))
const _31301a98 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _2a3420fe = () => interopDefault(import('../pages/directory.vue' /* webpackChunkName: "pages/directory" */))
const _54586100 = () => interopDefault(import('../pages/event-detail.vue' /* webpackChunkName: "pages/event-detail" */))
const _71b166ac = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _1c2e2bad = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _1556235d = () => interopDefault(import('../pages/faq/search.vue' /* webpackChunkName: "pages/faq/search" */))
const _08c0e910 = () => interopDefault(import('../pages/media.vue' /* webpackChunkName: "pages/media" */))
const _6a4397fb = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _2002c880 = () => interopDefault(import('../pages/media/events.vue' /* webpackChunkName: "pages/media/events" */))
const _64569cc1 = () => interopDefault(import('../pages/media/member.vue' /* webpackChunkName: "pages/media/member" */))
const _4cb91176 = () => interopDefault(import('../pages/media-detail.vue' /* webpackChunkName: "pages/media-detail" */))
const _9f5ed962 = () => interopDefault(import('../pages/meeting.vue' /* webpackChunkName: "pages/meeting" */))
const _0eb13555 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _9db14b34 = () => interopDefault(import('../pages/prices.vue' /* webpackChunkName: "pages/prices" */))
const _0ce0f500 = () => interopDefault(import('../pages/products.vue' /* webpackChunkName: "pages/products" */))
const _7601ec03 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _140b2472 = () => interopDefault(import('../pages/products/search.vue' /* webpackChunkName: "pages/products/search" */))
const _20e12886 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _460d2e78 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _5ae6910c = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _f78b227e = () => interopDefault(import('../pages/company/news.vue' /* webpackChunkName: "pages/company/news" */))
const _b1e5386c = () => interopDefault(import('../pages/email/verification.vue' /* webpackChunkName: "pages/email/verification" */))
const _2fec9730 = () => interopDefault(import('../pages/email/verify.vue' /* webpackChunkName: "pages/email/verify" */))
const _bf431e04 = () => interopDefault(import('../pages/page/the-greatest-mice-tourism-event-list.vue' /* webpackChunkName: "pages/page/the-greatest-mice-tourism-event-list" */))
const _265d635a = () => interopDefault(import('../pages/user/calculator.vue' /* webpackChunkName: "pages/user/calculator" */))
const _4467a45e = () => interopDefault(import('../pages/user/impersonate.vue' /* webpackChunkName: "pages/user/impersonate" */))
const _4f6cf6c1 = () => interopDefault(import('../pages/user/login.vue' /* webpackChunkName: "pages/user/login" */))
const _5784ba58 = () => interopDefault(import('../pages/user/login/successful.vue' /* webpackChunkName: "pages/user/login/successful" */))
const _297a2c3c = () => interopDefault(import('../pages/user/logout.vue' /* webpackChunkName: "pages/user/logout" */))
const _3a7f7d91 = () => interopDefault(import('../pages/user/registration.vue' /* webpackChunkName: "pages/user/registration" */))
const _3139a409 = () => interopDefault(import('../pages/user/ambassador/academy/index.vue' /* webpackChunkName: "pages/user/ambassador/academy/index" */))
const _d6c7e822 = () => interopDefault(import('../pages/user/provider/complete.vue' /* webpackChunkName: "pages/user/provider/complete" */))
const _e2e04fd0 = () => interopDefault(import('../pages/user/provider/master.vue' /* webpackChunkName: "pages/user/provider/master" */))
const _07d9c763 = () => interopDefault(import('../pages/user/provider/profile.vue' /* webpackChunkName: "pages/user/provider/profile" */))
const _a24faf7a = () => interopDefault(import('../pages/user/provider/profile/academy/index.vue' /* webpackChunkName: "pages/user/provider/profile/academy/index" */))
const _0c4dc624 = () => interopDefault(import('../pages/user/provider/profile/associations.vue' /* webpackChunkName: "pages/user/provider/profile/associations" */))
const _d2d5c156 = () => interopDefault(import('../pages/user/provider/profile/certifications.vue' /* webpackChunkName: "pages/user/provider/profile/certifications" */))
const _a563eb30 = () => interopDefault(import('../pages/user/provider/profile/dashboard.vue' /* webpackChunkName: "pages/user/provider/profile/dashboard" */))
const _6753f521 = () => interopDefault(import('../pages/user/provider/profile/dashboard.old.vue' /* webpackChunkName: "pages/user/provider/profile/dashboard.old" */))
const _c5add3ba = () => interopDefault(import('../pages/user/provider/profile/fairs.vue' /* webpackChunkName: "pages/user/provider/profile/fairs" */))
const _098f43c2 = () => interopDefault(import('../pages/user/provider/profile/invoices.vue' /* webpackChunkName: "pages/user/provider/profile/invoices" */))
const _a0ca9998 = () => interopDefault(import('../pages/user/provider/profile/company/additional-information.vue' /* webpackChunkName: "pages/user/provider/profile/company/additional-information" */))
const _169ebc50 = () => interopDefault(import('../pages/user/provider/profile/company/contact-person.vue' /* webpackChunkName: "pages/user/provider/profile/company/contact-person" */))
const _241a472a = () => interopDefault(import('../pages/user/provider/profile/company/general.vue' /* webpackChunkName: "pages/user/provider/profile/company/general" */))
const _268c60b6 = () => interopDefault(import('../pages/user/provider/profile/company/keyfacts.vue' /* webpackChunkName: "pages/user/provider/profile/company/keyfacts" */))
const _4965a5c7 = () => interopDefault(import('../pages/user/provider/profile/company/meeting-requests/index.vue' /* webpackChunkName: "pages/user/provider/profile/company/meeting-requests/index" */))
const _68bc3e9c = () => interopDefault(import('../pages/user/provider/profile/company/social-media.vue' /* webpackChunkName: "pages/user/provider/profile/company/social-media" */))
const _d26fd3fe = () => interopDefault(import('../pages/user/provider/profile/company/verified-member.vue' /* webpackChunkName: "pages/user/provider/profile/company/verified-member" */))
const _a7532cc2 = () => interopDefault(import('../pages/user/provider/profile/gmcs/status.vue' /* webpackChunkName: "pages/user/provider/profile/gmcs/status" */))
const _75624d07 = () => interopDefault(import('../pages/user/provider/profile/gmcs/survey.vue' /* webpackChunkName: "pages/user/provider/profile/gmcs/survey" */))
const _603b4101 = () => interopDefault(import('../pages/user/provider/profile/listings/case-studies/index.vue' /* webpackChunkName: "pages/user/provider/profile/listings/case-studies/index" */))
const _5e09a9ce = () => interopDefault(import('../pages/user/provider/profile/listings/destinations.vue' /* webpackChunkName: "pages/user/provider/profile/listings/destinations" */))
const _157ee4ca = () => interopDefault(import('../pages/user/provider/profile/listings/jobs.vue' /* webpackChunkName: "pages/user/provider/profile/listings/jobs" */))
const _3526a20a = () => interopDefault(import('../pages/user/provider/profile/listings/news/index.vue' /* webpackChunkName: "pages/user/provider/profile/listings/news/index" */))
const _707f595b = () => interopDefault(import('../pages/user/provider/profile/listings/products/index.vue' /* webpackChunkName: "pages/user/provider/profile/listings/products/index" */))
const _5119fc5e = () => interopDefault(import('../pages/user/provider/profile/listings/profiles.vue' /* webpackChunkName: "pages/user/provider/profile/listings/profiles" */))
const _7e4a34fc = () => interopDefault(import('../pages/user/provider/profile/listings/references.vue' /* webpackChunkName: "pages/user/provider/profile/listings/references" */))
const _0afcd7dc = () => interopDefault(import('../pages/user/provider/profile/settings/add-coupon.vue' /* webpackChunkName: "pages/user/provider/profile/settings/add-coupon" */))
const _a52810b8 = () => interopDefault(import('../pages/user/provider/profile/settings/change-mail.vue' /* webpackChunkName: "pages/user/provider/profile/settings/change-mail" */))
const _79462808 = () => interopDefault(import('../pages/user/provider/profile/settings/change-password.vue' /* webpackChunkName: "pages/user/provider/profile/settings/change-password" */))
const _5e6f6b0e = () => interopDefault(import('../pages/user/provider/profile/settings/newsletter.vue' /* webpackChunkName: "pages/user/provider/profile/settings/newsletter" */))
const _c8b986c0 = () => interopDefault(import('../pages/user/provider/profile/settings/update-credit-card.vue' /* webpackChunkName: "pages/user/provider/profile/settings/update-credit-card" */))
const _0a6dda59 = () => interopDefault(import('../pages/user/provider/profile/listings/news/add.vue' /* webpackChunkName: "pages/user/provider/profile/listings/news/add" */))
const _1d8d93b8 = () => interopDefault(import('../pages/user/provider/profile/academy/course/detail/_id/index.vue' /* webpackChunkName: "pages/user/provider/profile/academy/course/detail/_id/index" */))
const _197a5bd8 = () => interopDefault(import('../pages/user/provider/profile/academy/course/lessons/_id/index.vue' /* webpackChunkName: "pages/user/provider/profile/academy/course/lessons/_id/index" */))
const _55f93a57 = () => interopDefault(import('../pages/user/provider/profile/company/meeting-requests/detail/_id.vue' /* webpackChunkName: "pages/user/provider/profile/company/meeting-requests/detail/_id" */))
const _023d274e = () => interopDefault(import('../pages/user/provider/profile/listings/case-studies/edit/_id/index.vue' /* webpackChunkName: "pages/user/provider/profile/listings/case-studies/edit/_id/index" */))
const _2a2494ad = () => interopDefault(import('../pages/user/provider/profile/listings/news/edit/_id.vue' /* webpackChunkName: "pages/user/provider/profile/listings/news/edit/_id" */))
const _80ebcd82 = () => interopDefault(import('../pages/user/provider/profile/listings/products/edit/_id/index.vue' /* webpackChunkName: "pages/user/provider/profile/listings/products/edit/_id/index" */))
const _27cce72c = () => interopDefault(import('../pages/user/ambassador/academy/course/detail/_id/index.vue' /* webpackChunkName: "pages/user/ambassador/academy/course/detail/_id/index" */))
const _546d448e = () => interopDefault(import('../pages/user/ambassador/academy/course/lessons/_id/index.vue' /* webpackChunkName: "pages/user/ambassador/academy/course/lessons/_id/index" */))
const _4ecc086d = () => interopDefault(import('../pages/meeting-request/detail/_id/index.vue' /* webpackChunkName: "pages/meeting-request/detail/_id/index" */))
const _3a3962dd = () => interopDefault(import('../pages/meeting-request/response/_id/index.vue' /* webpackChunkName: "pages/meeting-request/response/_id/index" */))
const _4be91d66 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _2c3142a1,
    meta: {"module":"about","showNavSearch":true},
    name: "about"
  }, {
    path: "/academy",
    component: _2990a780,
    children: [{
      path: "/academy",
      component: _75218e03,
      meta: {"module":"academy","showNavSearch":true},
      name: "academy"
    }, {
      path: "courses",
      component: _c421b8ae,
      meta: {"module":"academy","showNavSearch":true},
      name: "academy.courses"
    }, {
      path: "course/detail/:id?/:slug",
      component: _57cf2c1f,
      meta: {"module":"academy","showNavSearch":true},
      props: true,
      name: "academy.course.detail"
    }, {
      path: "course/watch/:id?/:slug",
      component: _0bc9c1e3,
      meta: {"module":"academy","showNavSearch":true,"layout":"academy"},
      props: true,
      name: "academy.course.watch"
    }, {
      path: "/academy/course/lessons/:id/:slug",
      component: _57cf2c1f,
      meta: {"module":"academy","showNavSearch":true},
      props: route => ({ isLessonsRoute: true, id: route.params.id, slug: route.params.slug }),
      name: "academy.course.lessons"
    }]
  }, {
    path: "/buyerprograms",
    component: _09fde1b6,
    meta: {"module":"buyerprograms","showNavSearch":true},
    name: "buyerprograms"
  }, {
    path: "/contact",
    component: _31301a98,
    meta: {"module":"contact","showNavSearch":true},
    name: "page.contact"
  }, {
    path: "/directory",
    component: _2a3420fe,
    meta: {"module":"directory","showNavSearch":false},
    name: "directory"
  }, {
    path: "/event-detail",
    component: _54586100,
    meta: {"showNavSearch":true},
    name: "event-detail"
  }, {
    path: "/faq",
    component: _71b166ac,
    children: [{
      path: "/faq",
      component: _1c2e2bad,
      meta: {"module":"faq","showNavSearch":true},
      name: "faq"
    }, {
      path: "search",
      component: _1556235d,
      meta: {"module":"faq","showNavSearch":true},
      name: "faq-search"
    }, {
      path: "/faq/:category(provider|buyer)",
      component: _1c2e2bad,
      meta: {"module":"faq","showNavSearch":true},
      props: true,
      name: "faq/faq/:category(provider|buyer)"
    }]
  }, {
    path: "/media",
    component: _08c0e910,
    children: [{
      path: "/media",
      component: _6a4397fb,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      name: "media"
    }, {
      path: "events",
      component: _2002c880,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      name: "media-events"
    }, {
      path: "member",
      component: _64569cc1,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      name: "media-member"
    }, {
      path: "/media/page/:page(\\d+)",
      component: _6a4397fb,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: true,
      name: "media/media/page/:page(\\d+)"
    }, {
      path: "/media/:category(news|articles|videos)",
      component: _6a4397fb,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: true,
      name: "media/media/:category(news|articles|videos)"
    }, {
      path: "/media/news/page/:page(\\d+)",
      component: _6a4397fb,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: route => ({ category: 'news', page: route.params.page }),
      name: "media/media/news/page/:page(\\d+)"
    }, {
      path: "/media/articles/page/:page(\\d+)",
      component: _6a4397fb,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: route => ({ category: 'articles', page: route.params.page }),
      name: "media/media/articles/page/:page(\\d+)"
    }, {
      path: "/media/videos/page/:page(\\d+)",
      component: _6a4397fb,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: route => ({ category: 'videos', page: route.params.page }),
      name: "media/media/videos/page/:page(\\d+)"
    }, {
      path: "/media/member/page/:page",
      component: _64569cc1,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: true,
      name: "media-member/media/member/page/:page"
    }, {
      path: "/media/member",
      component: _64569cc1,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: true,
      name: "media-member/media/member"
    }]
  }, {
    path: "/media-detail",
    component: _4cb91176,
    meta: {"module":"media","showNavSearch":true},
    name: "media-detail"
  }, {
    path: "/meeting",
    component: _9f5ed962,
    name: "meeting"
  }, {
    path: "/newsletter",
    component: _0eb13555,
    meta: {"module":"newsletter","showNavSearch":true},
    name: "page.newsletter"
  }, {
    path: "/prices",
    component: _9db14b34,
    meta: {"module":"prices","showNavSearch":true,"noWrapperClass":true},
    name: "Prices"
  }, {
    path: "/products",
    component: _0ce0f500,
    children: [{
      path: "/products",
      component: _7601ec03,
      meta: {"module":"products","showNavSearch":false,"wrapperClass":"products-wrap"},
      name: "products"
    }, {
      path: "search",
      component: _140b2472,
      meta: {"module":"products","showNavSearch":false,"wrapperClass":"products-wrap"},
      name: "products.search"
    }, {
      path: "/products/page/:page(\\d+)",
      component: _7601ec03,
      meta: {"module":"products","showNavSearch":false,"wrapperClass":"products-wrap"},
      props: true,
      name: "products/products/page/:page(\\d+)"
    }]
  }, {
    path: "/profile/:id/:slug",
    component: _20e12886,
    meta: {"module":"provider.public","noWrapperClass":true},
    props: true,
    name: "provider.public.profile"
  }, {
    path: "/search",
    component: _460d2e78,
    meta: {"module":"search","noWrapperClass":true},
    name: "search"
  }, {
    path: "/services",
    component: _5ae6910c,
    meta: {"module":"services","showNavSearch":true},
    name: "services"
  }, {
    path: "/company/news",
    component: _f78b227e,
    meta: {"module":"profile","showNavSearch":true},
    name: "company-news"
  }, {
    path: "/email/verification",
    component: _b1e5386c,
    meta: {"module":"register"},
    name: "email-verification"
  }, {
    path: "/email/verify",
    component: _2fec9730,
    meta: {"module":"register"},
    name: "verification.notice"
  }, {
    path: "/page/the-greatest-mice-tourism-event-list",
    component: _bf431e04,
    name: "page-the-greatest-mice-tourism-event-list"
  }, {
    path: "/user/calculator",
    component: _265d635a,
    meta: {"module":"prices","showNavSearch":true,"noWrapperClass":true},
    name: "user.calculator"
  }, {
    path: "/user/impersonate",
    component: _4467a45e,
    meta: {"module":"login"},
    name: "user.impersonate"
  }, {
    path: "/user/login",
    component: _4f6cf6c1,
    meta: {"module":"login"},
    name: "user.login",
    children: [{
      path: "successful",
      component: _5784ba58,
      meta: {"module":"login"},
      name: "user.login.successful"
    }]
  }, {
    path: "/user/logout",
    component: _297a2c3c,
    meta: {"module":"logout"},
    name: "user.logout"
  }, {
    path: "/user/registration",
    component: _3a7f7d91,
    meta: {"module":"registration"},
    name: "user.registration"
  }, {
    path: "/user/ambassador/academy",
    component: _3139a409,
    meta: {"module":"ambassador","showNavSearch":false,"hideFooter":true},
    name: "ambassador.academy"
  }, {
    path: "/user/provider/complete",
    component: _d6c7e822,
    meta: {"module":"profile","onProfile":false},
    name: "user.provider.complete"
  }, {
    path: "/user/provider/master",
    component: _e2e04fd0,
    meta: {"module":"master","wrapperClass":"profiles"},
    name: "provider.master"
  }, {
    path: "/user/provider/profile",
    component: _07d9c763,
    name: "user-provider-profile",
    children: [{
      path: "academy",
      component: _a24faf7a,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles light"},
      name: "provider.profile.academy.courses"
    }, {
      path: "associations",
      component: _0c4dc624,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.associations"
    }, {
      path: "certifications",
      component: _d2d5c156,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.certifications"
    }, {
      path: "dashboard",
      component: _a563eb30,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.dashboard"
    }, {
      path: "dashboard.old",
      component: _6753f521,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.dashboard.old"
    }, {
      path: "fairs",
      component: _c5add3ba,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.fairs"
    }, {
      path: "invoices",
      component: _098f43c2,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.invoices"
    }, {
      path: "company/additional-information",
      component: _a0ca9998,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.company.additional-information"
    }, {
      path: "company/contact-person",
      component: _169ebc50,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.company.contact-person"
    }, {
      path: "company/general",
      component: _241a472a,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.company.general"
    }, {
      path: "company/keyfacts",
      component: _268c60b6,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.company.keyfacts"
    }, {
      path: "company/meeting-requests",
      component: _4965a5c7,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.meeting-requests"
    }, {
      path: "company/social-media",
      component: _68bc3e9c,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.company.social-media"
    }, {
      path: "company/verified-member",
      component: _d26fd3fe,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.company.verified-member"
    }, {
      path: "gmcs/status",
      component: _a7532cc2,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.gmcs.status"
    }, {
      path: "gmcs/survey",
      component: _75624d07,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles","hideFooter":true,"isGmcsSurvey":true},
      name: "provider.profile.gmcs.survey"
    }, {
      path: "listings/case-studies",
      component: _603b4101,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.case-studies"
    }, {
      path: "listings/destinations",
      component: _5e09a9ce,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.destinations"
    }, {
      path: "listings/jobs",
      component: _157ee4ca,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.jobs"
    }, {
      path: "listings/news",
      component: _3526a20a,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.news"
    }, {
      path: "listings/products",
      component: _707f595b,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.products"
    }, {
      path: "listings/profiles",
      component: _5119fc5e,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.profiles"
    }, {
      path: "listings/references",
      component: _7e4a34fc,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.references"
    }, {
      path: "settings/add-coupon",
      component: _0afcd7dc,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.settings.add-coupon"
    }, {
      path: "settings/change-mail",
      component: _a52810b8,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.settings.change-mail"
    }, {
      path: "settings/change-password",
      component: _79462808,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.settings.change-password"
    }, {
      path: "settings/newsletter",
      component: _5e6f6b0e,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.settings.newsletter"
    }, {
      path: "settings/update-credit-card",
      component: _c8b986c0,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.settings.update-credit-card"
    }, {
      path: "listings/news/add",
      component: _0a6dda59,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.news.add"
    }, {
      path: "academy/course/detail/:id",
      component: _1d8d93b8,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles light"},
      props: true,
      name: "provider.profile.academy.course.detail"
    }, {
      path: "academy/course/lessons/:id",
      component: _197a5bd8,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles light"},
      props: true,
      name: "provider.profile.academy.course.lessons"
    }, {
      path: "company/meeting-requests/detail/:id",
      component: _55f93a57,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      props: true,
      name: "provider.profile.meeting-requests.detail"
    }, {
      path: "listings/case-studies/edit/:id",
      component: _023d274e,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      props: true,
      name: "provider.profile.listings.case-studies.edit"
    }, {
      path: "listings/news/edit/:id?",
      component: _2a2494ad,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      props: true,
      name: "provider.profile.listings.news.edit"
    }, {
      path: "listings/products/edit/:id",
      component: _80ebcd82,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      props: true,
      name: "provider.profile.listings.products.edit"
    }]
  }, {
    path: "/user/ambassador/academy/course/detail/:id",
    component: _27cce72c,
    meta: {"module":"ambassador","showNavSearch":false,"hideFooter":true},
    name: "ambassador.academy.course.detail"
  }, {
    path: "/user/ambassador/academy/course/lessons/:id",
    component: _546d448e,
    meta: {"module":"ambassador","showNavSearch":false,"hideFooter":true},
    name: "ambassador.academy.course.lessons"
  }, {
    path: "/meeting-request/detail/:id",
    component: _4ecc086d,
    meta: {"module":"search","onProfile":false},
    props: true,
    name: "meeting-request.detail"
  }, {
    path: "/meeting-request/response/:id",
    component: _3a3962dd,
    meta: {"module":"search","onProfile":false},
    props: true,
    name: "meeting-request.response"
  }, {
    path: "/",
    component: _4be91d66,
    meta: {"module":"index"},
    name: "index"
  }, {
    path: "/media/event/:id(\\d+)/:slug",
    component: _54586100,
    meta: {"showNavSearch":true},
    props: true,
    name: "media.event.detail"
  }, {
    path: "/media/:id(\\d+)/:slug",
    component: _4cb91176,
    meta: {"module":"media","showNavSearch":true},
    props: true,
    name: "media.news.detail"
  }, {
    path: "/meeting/:id",
    component: _9f5ed962,
    props: route => ({ id: route.params.id, moderator: false }),
    name: "meeting.participant"
  }, {
    path: "/meeting/moderator/:id",
    component: _9f5ed962,
    props: route => ({ id: route.params.id, moderator: true }),
    name: "meeting.moderator"
  }, {
    path: "/profile/:id/:slug/:destinationId",
    component: _20e12886,
    meta: {"module":"provider.public","noWrapperClass":true},
    props: true,
    name: "provider.public.profile.destination.id"
  }, {
    path: "/profile/:id/:slug/:destinationId/:destinationSlug",
    component: _20e12886,
    meta: {"module":"provider.public","noWrapperClass":true},
    props: true,
    name: "provider.public.profile.destination.slug"
  }, {
    path: "/search/:type",
    component: _460d2e78,
    meta: {"module":"search","noWrapperClass":true},
    props: true,
    name: "search.type.name"
  }, {
    path: "/search/:type/page/:page",
    component: _460d2e78,
    meta: {"module":"search","noWrapperClass":true},
    props: true,
    name: "search.type.name.page"
  }, {
    path: "/search/country/:term/:slug",
    component: _460d2e78,
    meta: {"module":"search","noWrapperClass":true},
    props: route => ({ type: 'country', term: route.params.term, slug: route.params.slug }),
    name: "search.type.country"
  }, {
    path: "/search/country/:term/:slug/page/:page",
    component: _460d2e78,
    meta: {"module":"search","noWrapperClass":true},
    props: route => ({ type: 'country', term: route.params.term, slug: route.params.slug, page: route.params.page }),
    name: "search.type.country.page"
  }, {
    path: "/search/city/:country/:slug/:term",
    component: _460d2e78,
    meta: {"module":"search","noWrapperClass":true},
    props: route => ({ type: 'city', country: route.params.country, slug: route.params.slug, term: route.params.term,  }),
    name: "search.type.city"
  }, {
    path: "/search/city/:country/:slug/:term/page/:page",
    component: _460d2e78,
    meta: {"module":"search","noWrapperClass":true},
    props: route => ({ type: 'city', country: route.params.country, slug: route.params.slug, term: route.params.term, page: route.params.page }),
    name: "search.type.city.page"
  }, {
    path: "/in/country/:country/:slug",
    component: _460d2e78,
    meta: {"module":"search","noWrapperClass":true},
    props: route => ({ country: route.params.country, slug: route.params.slug, type: 'country', seo: true }),
    name: "search.seo.in.country"
  }, {
    path: "/in/country/:country/:slug/page/:page",
    component: _460d2e78,
    meta: {"module":"search","noWrapperClass":true},
    props: route => ({ country: route.params.country, slug: route.params.slug, page: route.params.page, type: 'country', seo: true }),
    name: "search.seo.in.country.page"
  }, {
    path: "/company/news/:companySlug/:id/:slug",
    component: _f78b227e,
    meta: {"module":"profile","showNavSearch":true},
    props: true,
    name: "company.news.detail"
  }, {
    path: "/email/verification/:id/:hash",
    component: _b1e5386c,
    meta: {"module":"register"},
    props: true,
    name: "email.verify.verification"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
